import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default (id) => ({
	id: id,
	swiper: null,

	init() {
		this.swiper = new Swiper(id, {
			spaceBetween: 32,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
		});
	},
});
