import "flickity";
import "flickity/css/flickity.css";

import "../css/isstd.css";

import Alpine from "alpinejs";

import slider from "./components/slider";

window.Alpine = Alpine;

Alpine.data("slider", slider);

Alpine.start();

// Toggle Mobile Menu
const headerToggle = document.querySelector(".header-toggle");
const headerMenu = document.querySelector(".header-menu");

headerToggle.addEventListener("click", () => {
	headerMenu.classList.toggle("-open");
});
